import {
  ApplicationConfig,
  inject, LOCALE_ID,
  provideZoneChangeDetection,
} from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import {
  HttpClient,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http';
import { provideNgIconLoader } from '@spartan-ng/ui-icon-helm';
import { withCaching } from '@ng-icons/core';
import { httpInterceptor } from '@hp-auth/_interceptors/http.interceptor';
import {provideTranslation} from "@hp-core/i18n/provide-translation";
import { DatePipe } from '@angular/common';

export const appConfig: ApplicationConfig = {
  providers: [
    { provide: LOCALE_ID, useValue: 'es-AR'},
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideTranslation,
    provideHttpClient(withInterceptors([httpInterceptor])),
    provideNgIconLoader((name) => {
      const http = inject(HttpClient);
      return http.get(`assets/icons/${name}.svg`, { responseType: 'text' });
    }, withCaching()),
    DatePipe
  ],
};
